import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Login from "@/views/login";
import store from "../store";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/customers/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/customers/company",
    name: "Company",
    component: () => import("@/views/customers/company"),
  },
  {
    path: "/customers/:id",
    name: "single-customer",
    component: () => import("@/views/customers/singleCustomer"),
  },
  {
    path: "/customers/cards/:id",
    name: "customer-issued-cards",
    component: () => import("@/views/customers/issuedCards"),
  },
  {
    path: "/employment/:id",
    name: "Employment",
    component: () => import("@/views/customers/employmentDetails"),
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "auth",
      guest: true,
    },
  },
  {
    path: "/merchants",
    name: "Merchants",
    component: () => import("@/views/merchants/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/merchants/categories",
    name: "Categories",
    component: () => import("@/views/merchants/categories"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/purchases",
    name: "purchases",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/purchases/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/purchases/payments",
    name: "payments",
    component: () => import("@/views/purchases/payments.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/verify-employment",
    name: "Verify-Employment",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/verifyEmployment/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/verify-bank",
    name: "Verify-Bank",

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/verifyBankStatement"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/partner-registration",
    name: "partner-registration",
    component: () => import("@/views/partnerRegistration/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("@/views/partners/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/partner-merchants/:id",
    name: "view-partner-merchants",
    component: () => import("@/views/partners/partnerMerchant.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/partner-customers/:id",
    name: "view-partner-customers",
    component: () => import("@/views/partners/partnerCustomer.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/currency-mappings",
    name: "currencymappingss",
    component: () => import("@/views/wire/currencyMappings/index.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/account",
    name: "wire-account",
    component: () => import("@/views/wire/account.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/registration",
    name: "wire-registration",
    component: () => import("@/views/wire/registration.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/account/:id",
    name: "single-wire-account",
    component: () => import("@/views/wire/singleWireAccount"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/account/senders/:wireAccountSendersId",
    name: "single-sender-info",
    component: () => import("@/views/wire/SingleSenderInfo.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
    props: route => ({
      wireAccountSendersId: route.params.wireAccountSendersId,
      wireAccountId: route.query.wireAccountId
    })
  },
  {
    path: "/wire/payments",
    name: "wire-payments",
    component: () => import("@/views/wire/payments.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/payments/:id",
    name: "single-payment",
    component: () => import("@/views/wire/singlePayments.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/payouts",
    name: "wire-payouts",
    component: () => import("@/views/wire/payouts.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/rate-adjustment",
    name: "wire-rate-adjustment",
    component: () => import("@/views/wire/rateAdjustment"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/wire/owner-disputes",
    name: "owner dispute",
    component: () => import("@/views/wire/ownerDispute"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/views/admin/admin.vue"),
    meta: {
      layout: "default",
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next();
      return;
    } else {
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    const expiryDate = new Date(store?.state?.auth?.tokenExpire);
    const currentDate = new Date().getTime();
    if (store.getters["auth/isAuthenticated"] && expiryDate > currentDate) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

//OTC form Directives


export default router;
